import React, {useRef, useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useStore} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {User} from '../../states/actions';
import {PreviewComponent, NodeComponent, ButtonState, onEdited} from './Components'
import { GlobalStateContext } from '../GlobalState';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

import {arrayMoveImmutable} from 'array-move';
import {SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';



const DragHandle = sortableHandle(() =>  
<span className="drag-handle">
<i className="dragicon"><img src="/images/dragbtn.png" alt="..." /></i>
</span>
);



const SortableItem = SortableElement(({value, text_en, text_ru, name, id, content, content_en, content_ru, img, handleInputChange, handleTextChange, handleTextEnChange, handleTextRuChange, handleContentChange, handleContentChangeEn, handleContentChangeRu, handleImgChange, remove, language }) => 
  
  
  {
    const {_id} = useSelector(User.get());
    
  /*   let currentContent = '';
    let currentHandleContentChange = () => {};
  
    if (language === 'en') {
      
      currentContent = content_en;
      currentHandleContentChange = handleContentChangeEn;
    } else if (language === 'ru') {
    
      currentContent = content_ru; // Ensure you have content_ru
      currentHandleContentChange = handleContentChangeEn; // Ensure you have this handler
    } else {
      currentContent = content;
      currentHandleContentChange = handleContentChange;
    } */

      const [isDraggable, setIsDraggable] = useState(true);
      const editorContainerRef = useRef(null);

      useEffect(() => {
        const stopPropagationForToolbar = () => {
          if (editorContainerRef.current) {
            // Get the toolbar element from CKEditor
            const toolbar = editorContainerRef.current.querySelector('.ck-toolbar');
    
            if (toolbar) {
              // Add event listener to each button in the toolbar
              toolbar.querySelectorAll('button').forEach((button) => {
                button.addEventListener('mousedown', (event) => {
                  event.stopPropagation(); // Prevent click event from initiating drag
                });
              });
            }
          }
        };
    
        // Add event listeners when CKEditor is initialized
        stopPropagationForToolbar();
      }, []);

  
  return (
  
  <div>
        <div className="double-input mb-3 sortable-item">    

             <DragHandle />

              <div className='inputs'>
          
          
              <input
                    id={text_en}
                    name={text_en}
                    className="form-control bottom"
                    placeholder="name en"
                    type="value"
                    defaultValue={text_en}
                    onChange={onEdited}
                    onBlur={handleTextEnChange}
                    />

            <input
                  id={text_ru}
                  name={text_ru}
                  className="form-control bottom"
                  placeholder="name ru"
                  type="value"
                  defaultValue={text_ru}
                  onChange={onEdited}
                  onBlur={handleTextRuChange}
                  />

              <input
                  id={id}
                 name={name}
                 className="form-control top"
                 placeholder="name"
                 defaultValue={name}
                 type="text"
                 onChange={onEdited}
                 onBlur={handleTextChange}
                />



                


             {/*  VAJA PAAR ASJA LAHENDADA VEEL: */}

             {/*  {language === 'en' ? (
                

                    <input
                    id={id}
                    name={text_en}
                    className="form-control bottom"
                    placeholder="name en"
                    type="text"
                    defaultValue={text_en}
                    onChange={onEdited}
                    onBlur={handleTextEnChange}
                    />



                ) : language === 'ru' && text_ru !== undefined  ? (

                  <>
                  <input
                  id={id + text_ru}
                  name={text_ru}
                  className="form-control bottom"
                  placeholder="name ru"
                  type="text"

                  defaultValue={text_ru}
                  onChange={onEdited}
                  onBlur={handleTextRuChange}
                  />

</>

                ) : language === 'default' ? (

                  <input
                  id={id}
                 name={name}
                 className="form-control top"
                 placeholder="name"
                 value = {name}

                 defaultValue={name}
                 type="text"
                 onChange={onEdited}
                 onBlur={handleTextChange}
                />



                ):(
                  <></>
                )} */}
          
             
                    
                

                <input
                  id={id}
                  name={value}
                  className="form-control bottom"
                  placeholder="value"
                  type="value"
                  defaultValue={value}
                  onChange={onEdited}
                  onBlur={handleInputChange}
                />

                <input
                  id={id}
                  name={img}
                  className="form-control bottom"
                  placeholder="img url"
                  type="value"
                  defaultValue={img}
                  onChange={onEdited}
                  onBlur={handleImgChange}
                />



                {language === 'en' && content_en !== undefined  ? (
                
                

                  <div>

                      <div id="content" ref={editorContainerRef}>
                              <CKEditor
                                onReady={(editor_en) => {
                                }}
                                config={
                                  {
                                    toolbar: ['bold', 'italic', 'link'],
                                    link: {
                                      addTargetToExternalLinks: true,
                                    },
                                  simpleUpload: {
                                    uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                                  }
                                  }
                              }
                                onChange={onEdited}
                                editor={ClassicEditor}
                                data={content_en}
                                onBlur={(e, editor_en) => {
                                  const editorcontent = editor_en.getData()
                                  handleContentChangeEn(editorcontent);
                                }}
                
                              />
                      </div>
                              
                    </div>

                ) : language === 'ru' && content_ru !== undefined ? (

                  <div>

                      <div id="content" ref={editorContainerRef}>
                              <CKEditor
                                onReady={(editor_ru) => {
                                }}
                                config={
                                  {
                                    toolbar: ['bold', 'italic', 'link'],
                                    link: {
                                      addTargetToExternalLinks: true,
                                    },
                                  simpleUpload: {
                                    uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                                  }
                                  }
                              }
                                onChange={onEdited}
                                editor={ClassicEditor}
                                data={content_ru}
                                onBlur={(e, editor_ru) => {
                                  const editorcontent = editor_ru.getData()
                                  handleContentChangeRu(editorcontent);
                                }}
                
                              />
                      </div>
                              
                    </div>

                ) :(


                  <div>
                      <div id="content" ref={editorContainerRef}>
                              <CKEditor
                                onReady={(editor) => {
                                }}
                                config={
                                  {
                                    toolbar: ['bold', 'italic', 'link'],
                                    link: {
                                      addTargetToExternalLinks: true,
                                    },
                                    simpleUpload: {
                                      uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                                    }
                                  }
                                }
                                onChange={onEdited}
                                editor={ClassicEditor}
                                data={content}
                                onBlur={(e, editor) => {
                                  const editorcontent = editor.getData()
                                  handleContentChange(editorcontent);
                                }}
                                onFocus={() => setIsDraggable(false)}
                
                              />
                    </div>
                              
                    </div>

                 )}

                



                 {/*  <div>
                      <div id="content">
                        <CKEditor
                          onReady={(editor) => {}}
                          config={{
                            toolbar: ['bold', 'italic', 'link'],
                            link: {
                              addTargetToExternalLinks: true,
                            },
                            simpleUpload: {
                              uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                            },
                          }}
                          editor={ClassicEditor}
                          data={currentContent}
                          onChange={(e, editor) => {
                            onEdited(e);
                            const editorContent = editor.getData();
                            currentHandleContentChange(editorContent);
                          }}
                        />
                      </div>
                    </div>
 */}

              </div>
        
              <button onClick={remove} className="fa fa-trash-alt icon red"></button>
      </div>
  </div>
)});


const SortableList = SortableContainer(({ items, handleContentChange, handleChange, remove, language }) => (
  <ul className="sortablelist">
    {Object.keys(items).map((key, i) => (
      <SortableItem
        id={items[key].id}
        next={items[key].next}
        key={key}
        index={i}
        name={items[key].text}
        value={items[key].value}
        text_en={items[key].text_en}
        text_ru={items[key].text_ru}
        content={items[key].content}
        content_en={items[key].content_en}
        content_ru={items[key].content_ru}

        img={items[key].img}
        language={language}
        handleImgChange={(e) => handleChange(e, i, 'img')}

        handleTextChange={(e) => handleChange(e, i, 'text')}
        handleInputChange={(e) => handleChange(e, i, 'value')}
        handleTextEnChange={(e) => handleChange(e, i, 'text_en')}
        handleTextRuChange={(e) => handleChange(e, i, 'text_ru')}
        
        handleContentChange={(editorcontent) => handleContentChange(editorcontent, i, 'content')}
        handleContentChangeEn={(editorcontent) => handleContentChange(editorcontent, i, 'content_en')}
        handleContentChangeRu={(editorcontent) => handleContentChange(editorcontent, i, 'content_ru')}

        remove={(e) => remove(items[key].id)}
      />
    ))}
  </ul>
));


const zoomSelector = (s) => s.transform[2];




export const DecisionBlock = (node) => {

 
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());


  const bot = useSelector(Chatbot.get());
  const zoom = useStore(zoomSelector);
  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);

  
  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);


  if (!block) {
    return null;
  }
  const {
    data,
    data: {decisions = {}},
  } = block;



  return (
    <>
      {!previewMode &&(
              <NodeComponent bot={bot} block={block} name="Decision" icon="decision" data={data} node={node} start={start} routes={decisions} zoom={zoom}/>
      )}
      {previewMode && (
        //<div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
              <PreviewComponent bot={bot} data={data} node={node} start={start} decisions={decisions}/>
        //</div>
      )}
    </>
  );
};

export const DecisionEditor = ({nodeid, block, data, decisions, language}) => {

  const refTxtText = useRef(null);
  const refTxtValue = useRef(null);

  const [deci, setDeci] = useState(decisions)


  useEffect(() => {
    setDeci(decisions)
   // console.log(deci, decisions)
  }, [decisions]);



  useEffect(() => {
    console.log("UUs keel ", language)
   // console.log(deci, decisions)
  }, [language]);


  if (!block) {
    return null;
  }


 
  const handleSortEnd = ({node, oldIndex, newIndex }) => {
    console.log(decisions, deci)
    const deciArray = Object.values(deci);
    const newItems = arrayMoveImmutable(deciArray, oldIndex, newIndex);
    const updateItems = newItems.map((item, index) => {
      return { ...item };
    });
    const updatedDeci = updateItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setDeci(updatedDeci);
    const data = {
      decisions: updatedDeci
     };
    Chatbot.setBlockData(nodeid, data);
  };

  const handleChange = async (e, index, label) => {
    const deciArray = Object.values(deci);
    deciArray[index] = {
      id: deciArray[index].id,
      [label]: e.target.value,
    }
    const updatedDeci = deciArray.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setDeci(updatedDeci);
    const data = {
      decisions: updatedDeci
     };
     Chatbot.setBlockData(nodeid, data);
  };

  const handleContentChange = async (editorcontent, index, label) => {
    console.log("SAVE:" , label)
    const deciArray = Object.values(deci);
    deciArray[index] = {
      id: deciArray[index].id,
      [label]:  editorcontent,
    }
    const updatedDeci = deciArray.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setDeci(updatedDeci);
    const data = {
      decisions: updatedDeci
     };
     Chatbot.setBlockData(nodeid, data);
  };

  const handleContentChangeEn = async (editorcontent, index, label) => {
    const deciArray = Object.values(deci);
    deciArray[index] = {
      id: deciArray[index].id,
      [label]:  editorcontent,
    }
    const updatedDeci = deciArray.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setDeci(updatedDeci);
    const data = {
      decisions: updatedDeci
     };
     Chatbot.setBlockData(nodeid, data);
  };

  const addDecisionField = (e) => {
    console.log(decisions)
    e.preventDefault();
    const id = Date.now().toString();
    const text = refTxtText.current.value;
    const value = refTxtValue.current.value;
    const text_en = '';
    const text_ru = '';

    const content = '';
    const content_en = '';
    const content_ru = '';
    

    const img = "";
    if (text !== '') {
      Chatbot.setBlockData(nodeid, {decisions: {[id]: {id, text, text_en, text_ru, value, content, content_en, content_ru, img}}});
      refTxtText.current.value = '';
      refTxtValue.current.value = '';
   
      onEdited('added');
    }
  };

  const removeDecisionField = async (id) => {
    Chatbot.removeBlockDecision(nodeid, id);
    onEdited();
  };

  const handleBlur = (e) => {   

    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
   };
  const onSortStart = ({ node }) => {
    console.log(node)
  };


  return (
    <>
    
    <div className='section'>ROUTES</div>

    <div className='double-input mb-3'>

        <div className='inputs'>
     
        <input
            type="input"
            id="txtText"
            className="form-control top"
            placeholder="Text"
            ref={refTxtText}
            onKeyPress={(e) => (e.key === 'Enter' ? addDecisionField(e) : e)}
          />


          <input
            type="input"
            id="txtValue"
            className="form-control bottom"
            placeholder="value"
            ref={refTxtValue}
            onKeyPress={(e) => (e.key === 'Enter' ? addDecisionField(e) : e)}
          />
          


       


        </div>

        <span className="icon" onClick={(e) => addDecisionField(e)}>
        <i className="fa fa-plus"></i>
      </span>
    </div>



    <SortableList items={deci} onSortEnd={handleSortEnd} helperClass="sortable" onSortStart={onSortStart} handleChange={handleChange}  handleContentChange={handleContentChange} remove={removeDecisionField} language={language}/>


    <ButtonState block={block} data={data} dataname="isDateTime" label="Record date and time"/>

    </>
  )
}
