import React, {useRef, useEffect, useState, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import Select from 'react-select';

import {Chatbot} from '../../states/actions';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {User} from '../../states/actions';
import ClassicEditor from 'ckeditor5-custom-build';
import {InputComponent, SelectComp, TextareaComponent, PreviewComponent, NodeComponent} from './Components'
import { GlobalStateContext } from '../GlobalState';

const zoomSelector = (s) => s.transform[2];


export const PaymentBlock = React.memo((node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  


  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());

  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);

  const zoom = useStore(zoomSelector);

  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);

  if (!block) {
    return null;
  }


  const variableOptions = Chatbot.getBlockVariables();
  const {
    data,
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {!previewMode &&(
        <NodeComponent bot={bot} block={block} name="Payment" icon="payment" data={data} node={node} start={start} zoom={zoom}/>

      )}
      {previewMode &&(
        // <div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
         <PreviewComponent bot={bot} data={data} node={node} start={start}/>
        // </div>

      )}
    </>
  );
});


export const PaymentEditor = ({nodeid, block, data, variableoptions}) => {

  let variables_paymenttype = [
    {
      label: 'Payment Initation',
      value: 'paymentInitiation',
      content: 'paymentInitiation',
    },
    {
      label: 'Card Payments',
      value: 'cardPayments',
      content: 'cardPayments',
    },
  ];

  let billing_type = [
    {
      label: 'Generate',
      value: 'generate',
      content: 'generate',
    },
    {
      label: 'Scoro',
      value: 'scoro',
      content: 'scoro',
    },
  ];

    const handleBlur = (e) => {   
      Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
    };
 
    return (
      <>
         

            <div className='section'>PAYMENT</div>

            <SelectComp data={data} name="Invoice Method" dataname="billingtype" placeholder="Select" options={billing_type} block={block} value="value"/>

            {data.billingtype === 'scoro' && ( 
              <>
                   <SelectComp data={data} name="Import invoice" dataname="imported_invoice" placeholder="Import invoice:" options={variableoptions} block={block} value="label"/>
                    Invoice has to be defined before this block!
                    <br/>
                    <br/>
              </>
            )}

            {/* <InputComponent data={data} name="Billing ID variable" dataname="merchantref" onBlur={handleBlur}/> */}

            {/* <InputComponent data={data} name="Price" dataname="amount" onBlur={handleBlur}/> */}
            <SelectComp data={data} name="Price" dataname="amount" placeholder="Select price" options={variableoptions} block={block} value="label"/>


            {/* TODO fix save option value to label and remove optional value, check if there are some that work differently */}

            <SelectComp data={data} name="First Name" dataname="first_name" placeholder="Select" options={variableoptions} block={block} value="label"/>
          
            <SelectComp data={data} name="Last Name" dataname="last_name" placeholder="Select" options={variableoptions} block={block} value="label"/>

            <SelectComp data={data} name="Email" dataname="email" placeholder="Select" options={variableoptions} block={block} value="label"/>

            <SelectComp data={data} name="Type" dataname="paymenttype" placeholder="Select" options={variables_paymenttype} block={block} value="value"/>


      </>
    )
}
